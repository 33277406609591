<template>
  <component :is="contentComponent" :themeConfig="themeConfig" :showDebug="showDebug"
    :deviceType="deviceType" :host="host" :adsense="adsenseConfig" :domainConfig="domainConfig" />
</template>

<script>

const theme_template = () => import('@/pages/template/theme.vue') //代码切割
const pc_template = () => import('@/pages/template/theme_pc.vue') //代码切割
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      contentComponent: null,
    };
  },
  computed: {
    ...mapGetters([
      'themeConfig',
      'domainConfig',
      'showDebug',
      'deviceType',
      'host',
      'adsenseConfig'
    ])
  },
  created () {
    // console.log(`deviceType - ${this.deviceType}`);
    if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
      this.contentComponent = pc_template
      return
    }
    this.contentComponent = theme_template
  },
  props: ['channel'],
  mounted () {
    console.log('当前的 channel 参数:', this.channel)
    // 可以在这里使用 channel 进行其他操作
  }
};
</script>
